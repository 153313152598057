import React, { useState, useEffect } from 'react';
import { bool, array, string } from 'prop-types';
import { navigate } from 'gatsby';
import { ThemeProvider } from '@material-ui/core/styles';
import {
  Container, BottomNavigation,
  BottomNavigationAction, Tooltip,
} from '@material-ui/core';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as svgIcons from '@fortawesome/pro-light-svg-icons';
import { faAndroid, faApple } from '@fortawesome/free-brands-svg-icons';

import {
  HOME_LOGGED_IN_ROUTE, SUBSCRIPTION_ROUTE, ACCOUNT_ROUTE,
} from 'constants/navigation';
import {
  HOME_NAV, SUBSCRIPTION_NAV, ACCOUNT_NAV,
} from 'constants/font-awesome';

import { footerTheme, isDark } from 'theme';
import useStyles, { useToolTipClasses } from './styles';

const homeNav = { route: HOME_LOGGED_IN_ROUTE, icon: HOME_NAV, message: 'Home' };
const subscriptionNav = { route: SUBSCRIPTION_ROUTE, icon: SUBSCRIPTION_NAV, message: 'Subscription' };
const accountNav = { route: ACCOUNT_ROUTE, icon: ACCOUNT_NAV, message: 'Account' };

const getIcon = (icon) => {
  if (icon === 'faApple') {
    return faApple;
  }
  return icon === 'faAndroid' ? faAndroid : svgIcons[icon];
};

const ApplicationFooter = ({
  sticky = false,
  navItems = [],
  currentItem = '',
  hasSubscription = false,
  homeless = false,
}) => {
  const styles = useStyles();
  const toolTipClasses = useToolTipClasses();

  const [builtNavItems, setBuiltNavItems] = useState([]);
  const [footerItem, setFooterItem] = useState(currentItem);
  const [footerStyle, setFooterStyle] = useState('darkFooter');

  useEffect(() => {
    const waitToLoad = () => {
      if (sticky) {
        setFooterStyle(isDark() ? 'stickyDarkFooter' : 'stickyLightFooter');
      } else {
        setFooterStyle(isDark() ? 'darkFooter' : 'lightFooter');
      }

      if (navItems.length > 2) {
        setBuiltNavItems(homeless ? [...navItems] : [homeNav, ...navItems]);
      } else if (hasSubscription) {
        setBuiltNavItems(homeless ? [...navItems, accountNav] : [
          homeNav,
          ...navItems,
          accountNav,
        ]);
      } else {
        setBuiltNavItems(homeless ? [...navItems, subscriptionNav] : [
          homeNav,
          ...navItems,
          subscriptionNav,
        ]);
      }
    };
    waitToLoad();

    // eslint-disable-next-line
  }, [navItems, sticky]);

  return (
    <ThemeProvider theme={footerTheme}>
      <footer className={styles[footerStyle]}>
      <Container className={styles.container}>

          <BottomNavigation
            value={footerItem}
            className={styles.bottomNavContainer}
            onChange={(event, newValue) => {
              if (newValue) {
                setFooterItem(newValue);
              } else {
                setFooterItem(currentItem);
              }
            }} >
          {builtNavItems && builtNavItems.map((item, key) => {
            const { icon, route, message } = item;
            return footerItem === message ? (
                <BottomNavigationAction
                  key={key}
                  label={message}
                  value={message}
                  icon={<FontAwesomeIcon icon={getIcon(icon)} size="lg" className={styles.icon}/>}
                  onClick={() => navigate(route)}
                  className={styles.bottomNav}
                  />
            ) : (
                <Tooltip
                  key={key}
                  title={message}
                  aria-label={message}
                  placement="top"
                  classes={toolTipClasses}
                  arrow>
                  <BottomNavigationAction
                    label={message}
                    value={message}
                    icon={<FontAwesomeIcon icon={getIcon(icon)} size="lg" className={styles.icon}/>}
                    onClick={() => navigate(route)}
                    className={styles.bottomNav}
                    />
                </Tooltip>
            );
          })}
          </BottomNavigation>
      </Container>
      </footer>
    </ThemeProvider>
  );
};

ApplicationFooter.propTypes = {
  sticky: bool,
  navItems: array,
  currentItem: string,
  hasSubscription: bool,
  homeless: bool,
};

export default ApplicationFooter;
